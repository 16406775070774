<template>
    <Header :isShow="isShow" />
    <div class="banner">
        <img src="@/assets/images/search.jpg" alt="" />
    </div>

    <div class="searchBox">
        <input placeholder="搜索" v-model="searchValue" @keyup.enter="onSubmit" />
        <router-link v-if="searchValue!=''" class="fourth text" :to="`/search/${searchValue}`">搜索</router-link>
        <p v-if="searchValue==''" class="fourth text">搜索</p>
    </div>
    <!-- 导航 -->
    <!-- <div class="navigation_box">
        <div class="navigation max_small_box">
            <div :class="navIndex == index ? 'list list_active' : 'list' " v-for="(item, index) in navigationList" :key="index" @click="handleNavIndex(index)">
                <p class="name">{{item.name}}</p>
            </div>
        </div>
    </div> -->
    <div class="content_box" ref="header">
        <div class="content max_small_box" >
            <div v-if="serviceList">
                <div class="second" v-if="service_total_page > 0">
                    <p class="second_title">服务搜索结果</p>
                    <div class="list" v-for="(item, index) in serviceList" :key="index">
                        <router-link :to="`/industry/${item.third_navigation_id}${item.page_id}/${item.second_navigation_id}/${item.index}`" v-if="item.page_id">
                            <div class="img">
                                <img v-if="item.image == '' || item.image == null" src="@/assets/images/a900a965ed5d1453b71abb6d303d0ab.jpg" alt="" />
                                <img v-else :src="imgUrl + item.image" alt="" />
                            </div>
                            <div class="text">
                                <p class="time">{{item.releasetime}}</p>
                                <p class="title">{{item.name}}</p>
                                <p class="desc" v-if="item.intro">{{item.intro}}</p>
                                <p class="more">查看更多<span class="iconfont">&#xe618;</span></p>
                            </div>
                        </router-link>
                        <router-link :to="`/serviceDetail/${item.id}`" v-else>
                            <div class="img">
                                <img v-if="item.image == '' || item.image == null" src="@/assets/images/a900a965ed5d1453b71abb6d303d0ab.jpg" alt="" />
                                <img v-else :src="imgUrl + item.image" alt="" />
                            </div>
                            <div class="text">
                                <p class="time">{{item.releasetime}}</p>
                                <p class="title">{{item.name}}</p>
                                <p class="desc" v-if="item.intro">{{item.intro}}</p>
                                <p class="more">查看更多<span class="iconfont">&#xe618;</span></p>
                            </div>
                        </router-link>
                    </div>
                    <!-- <p class="nodata" v-if="serviceList.service_data.data.length == 0">暂无数据~</p> -->
                    <div class="pagination max_small_box">
                        <a-pagination v-model:current="servicePage" :total="service_total_page" @change="handleChangePage" />
                    </div>
                </div>
            </div>
            <div v-if="newsList">
                <div class="second" v-if="news_total_page > 0">
                    <p class="second_title">新闻搜索结果</p>
                    <div class="list" v-for="(item, index) in newsList" :key="index">
                        <router-link :to="`/news/news_detail/${item.id}`">
                            <div class="img">
                                <img v-if="item.image == '' || item.image == null" src="@/assets/images/a900a965ed5d1453b71abb6d303d0ab.jpg" alt="" />
                                <img v-else :src="imgUrl + item.image" alt="" />
                            </div>
                            <div class="text">
                                <p class="time">{{item.releasetime.split(' ')[0]}}</p>
                                <p class="title">{{item.title}}</p>
                                <p class="desc" v-if="item.intro">{{item.intro}}</p>
                                <p class="more">查看更多<span class="iconfont">&#xe618;</span></p>
                            </div>
                        </router-link>
                    </div>
                    <!-- <p class="nodata" v-if="newsList.news_data.data.length == 0">暂无数据~</p> -->
                    <div class="pagination max_small_box">
                        <a-pagination v-model:current="newsPage" :total="news_total_page" @change="handleNewsChangePage" />
                    </div>
                </div>
            </div>
            <template v-if="serviceList && newsList">
                <p class="nodata" v-if="serviceList.total == 0 && newsList.total == 0">暂无数据~</p>
            </template>
            
        </div>
    </div>
</template>

<script>
import { ref, reactive, watchEffect } from 'vue'
import { useRoute,useRouter } from 'vue-router'
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref('')

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//轮播图下的导航处理函数
const navigationEffect = (route) => {

    //列表
    const navigationList = reactive([{id:'news_data', name:'新闻'},{id: 'service_data', name:'服务'}])
    const navIndex = ref(0)

    const page_id = ref('')

    const serviceList = ref()

    //页数
    const servicePage = ref(1)

    //总页数
    const service_total_page = ref()

    const newsList = ref()

    //页数
    const newsPage = ref(1)

    //总页数
    const news_total_page = ref()

    //获取服务列表
    const getServiceList = async(id) => {
        let postData = {
            title: id,
            page: servicePage.value
        }
        await post('/index/get_search_service', postData).then((res) => {
            // console.log('服务列表', res)
            if(res.code===1){
                serviceList.value = res.data.data
                service_total_page.value = res.data.last_page * 10
                // service_total_page.value = res.data.current_page
            }
        })
    }

    //获取新闻列表
    const getNewsList = async(id) => {
        let postData = {
            title: id,
            page: newsPage.value
        }
        await post('/index/get_search_news', postData).then((res) => {
            // console.log('新闻列表', res)
            if(res.code===1){
                newsList.value = res.data.data
                news_total_page.value = res.data.last_page * 10
                // news_total_page.value = res.data.current_page
            }
        })
    }

    //更换服务页数
    const handleChangePage = (new_page, pageSize) =>{
        // console.log(new_page)
        servicePage.value = new_page
    }

    //更换新闻页数
    const handleNewsChangePage = (new_page, pageSize) =>{
        // console.log(new_page)
        newsPage.value = new_page
    }

    watchEffect(() => {
        if(route.params.id){
            // console.log('搜索内容',route.params.id)
            getServiceList(route.params.id)
            getNewsList(route.params.id)
        }
        
    })


    //更换index
    const handleNavIndex = (index) => {
        
    }

    return { 
        navigationList, 
        handleNavIndex,
        navIndex,
        page_id,
        getServiceList,
        serviceList,
        servicePage,
        service_total_page,
        handleChangePage,
        newsList,
        newsPage,
        news_total_page,
        handleNewsChangePage
    }
}

export default {
    name: 'Search',
    components: {Header},
    setup() {
        const  route = useRoute()
        const  router = useRouter()

        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()


        const { 
            navigationList, 
            handleNavIndex,
            navIndex,
            page_id,
            serviceList,
            servicePage,
            service_total_page,
            handleChangePage,
            newsList,
            newsPage,
            news_total_page,
            handleNewsChangePage
        } = navigationEffect(route)

        
        const searchValue = ref('')  //搜索框的值

        //回车搜索
        const onSubmit = () => {
            router.push({ path: `/search/${searchValue.value}` })
        }
        //获取滑块到顶部的距离
        const header = ref(null)

        const { imgUrl }  = api


        watchEffect(() => {
            if(route.params.id){
                // console.log('该白你？？？？？',route.params.id)
                searchValue.value = route.params.id
            }
            
        })
        return { 
            imgUrl, 
            isShow, 
            header, 
            onSubmit,
            navigationList, 
            handleNavIndex,
            navIndex,
            page_id ,
            serviceList,
            servicePage,
            service_total_page,
            handleChangePage,
            newsList,
            newsPage,
            news_total_page,
            handleNewsChangePage,searchValue
        }
    },
}
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';
/* 轮播图 */
.banner{
    margin-top: 114px;
    @media screen and (max-width: 1200px){
        margin-top: 92px;
    }
    img{
        width: 100%;
        display: block;
    }
}
/* 导航 */
.navigation_box{
    background: rgba(255, 255, 255, .8);
    margin-top: -66px;
    position: relative;
    z-index: 9;
    @media screen and (max-width: 1200px){
        margin-top: 0px;
    }
}
.navigation{
    display: flex;
    align-items: center;
    @media screen and (max-width: 1200px){
        flex-wrap: wrap;
    }
    .list{
        width: 50%;
        text-align: center;
        transition: .3s all;
        &:hover{
            background: #2F509E;
            .name{
                color: #fff;
                font-weight: bold;
            }
        }
        @media screen and (max-width: 1200px){
            width: 33.3%;
            margin-bottom: 10px;
        }
        .name{
            color: rgba(16, 16, 16, 100);
            font-size: 16px;
            border-right: 1px solid #bbb;
            padding: 25px 0;
            cursor: pointer;
            transition: .3s all;
            @media screen and (max-width: 900px){
                font-size: .14rem;
            }
        }
    }
    .list_active{
        background: #2F509E;
        .name{
            color: #fff;
            font-weight: bold;
        }
    }
}
.content_box{
    margin-top: 40px;
}
.content{
    .second{
        margin-top: 40px;
        @media screen and (max-width: 1200px){
            padding: 0 30px;
        }
        .second_title{
            color: #305aa8;
            font-size: 20px;
            font-weight: bold;
            margin-top: 30px;
            margin-bottom: 30px;
            padding-bottom: 20px;
            border-bottom: 2px solid #e6e6e6;
        }
        .list{
            cursor: pointer;
            transition: .3s all;
            &:hover{
                background: $content-backgroundcolor;
                .time{
                    color: #fff;
                }
            }
            &:hover .text .time{
                color: #fff;
            }
            &:hover .text .title{
                color: #fff;
            }
            &:hover .text .desc{
                color: #fff;
            }
            &:hover .text .more{
                color: #fff;
            }
            a{
                display: flex;
                @media screen and (max-width: 1200px){
                    display: block;
                }
                .img{
                    width: 20%;
                    height: 200px;
                    padding: 2%;
                    @media screen and (max-width: 1200px){
                        width: 100%;
                        height: 200px;
                    }
                    @media screen and (max-width: 900px){
                        height: 250px;
                    }
                    @media screen and (max-width: 600px){
                        height: 250px;
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .text{
                    width: 63%;
                    margin-left: 4%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    @media screen and (max-width: 1200px){
                        width: 100%;
                        margin-bottom: 30px;
                        margin-left: 0%;
                    }
                    .time{
                        color: #A4A4A4;
                        font-size: 16px;
                        font-weight: 600;
                        @media screen and (max-width: 900px){
                            font-size: .16rem;
                        }
                    }
                    .title{
                        color: #101010;
                        font-size: 18px;
                        font-weight: 600;
                        margin-top: 20px;
                        line-height: 30px;
                        @media screen and (max-width: 900px){
                            font-size: .16rem;
                            margin-top: 0;
                        }
                    }
                    .desc{
                        color: rgba(16, 16, 16, 100);
                        font-size: 16px;
                        margin-top: 20px;
                        line-height: 20px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        @media screen and (max-width: 900px){
                            font-size: .14rem;
                        }
                    }
                    .more{
                        color: #101010;
                        font-size: 16px;
                        margin-top: 20px;
                        width: max-content;
                        border-radius: 10px;
                        cursor: pointer;
                        @media screen and (max-width: 900px){
                            font-size: .14rem;
                            margin-top: 10px;
                        }
                        span{
                            margin-left: 15px;
                        }
                    }
                }
            }
        }
        .nodata{
            text-align: center;
        }
    }
}
.pagination{
    text-align: center;
    padding-top: 30px;
    display: block;
}
.nodata{
    text-align: center;
}
.searchBox{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    input{
        width: 30%;
        height: 50px;
        outline: none;
        border: 1px solid rgba(187, 187, 187, 100);
        padding: 15px 10px;
        color: #868686;
        font-size: 16px;
        /* margin-right: 20px; */
        @media screen and (max-width: 900px){
            font-size: .14rem;
            width: 50%;
        }
    }

    .fourth{
        text-align: center;
        width: 100px;
        height: 50px;
        background-color: rgba(47, 80, 158, 100);
        border: 1px solid rgba(47, 80, 158, 100);
        font-size: 16px;
        /* border: none; */
        color: #fff;
        padding: 15px 0;
        cursor: pointer;
        @media screen and (max-width: 900px){
            font-size: .14rem;
        }
    }

}
</style>
